
import { defineComponent, onMounted, ref } from "vue";
import { useRoute , useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import axios from "axios";
const listSyllabuses = ref();
export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    const checkActive = (match) => {
      if (match == "หลักสูตรตามกฎหมาย") {
        return route.path.indexOf("mandatory") !== -1;
      } else if (match == "ศูนย์ช่วยเหลือ") {
        if (
          route.path.indexOf("qaboard") > -1 ||
          route.path.indexOf("help") > -1 ||
          route.path.indexOf("contactus") > -1
        ) {
          return true;
        } else {
          return false;
        }
      }
    };
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getSyllabus?&sizeContents=10&page=0&orderBy=1&sort=1&enable=1",
          { headers: {
            token: localStorage.getItem("id_token")? localStorage.getItem("id_token") :localStorage.getItem("tokenGuest")
          } }
        )
        .then(({ data }) => {
          listSyllabuses.value = data.data.content;
        })
        .catch(({ error }) => {
          localStorage.clear();
          router.go(0);
          // window.location.reload();
          console.log(error);
        });
    });

    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      translate,
      route,
      listSyllabuses,
      checkActive,
    };
  },
});
